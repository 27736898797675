import ReactModal from 'react-modal'
import { X } from 'react-feather'

/*
We are adding this here as the __next element
is not available in the test environment
*/
if (process.env.NODE_ENV !== 'test') ReactModal.setAppElement('#__next')

type Props = {
  isOpen: boolean
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
  width?: string
  children: JSX.Element | JSX.Element[]
  shouldCloseOnEsc?: boolean
  className?: string
  id?: string
  isCrossIconShown?: boolean
  isOverflowHidden?: boolean
  isOverflowAuto?: boolean
  handleCrossIconClick?: () => void
}

const Modal = ({
  isOpen,
  setIsOpen,
  width = 'sm:max-w-lg',
  children,
  shouldCloseOnEsc = true,
  className = 'bg-white',
  id = '',
  isCrossIconShown = false,
  isOverflowHidden = true,
  isOverflowAuto = true,
  handleCrossIconClick = () => setIsOpen(false),
}: Props): JSX.Element => {
  return (
    <ReactModal
      className="fixed inset-0 inset-x-0 flex items-center justify-center px-4 pb-4 outline-none"
      overlayClassName="z-50 fixed inset-0 bg-smoke"
      id={id}
      isOpen={isOpen}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={() => setIsOpen(false)}
    >
      <>
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 opacity-75 bg-grey"></div>
        </div>
        <div
          className={`bg-white rounded-lg shadow-xl transform transition-all sm:w-full ${width} ${
            isOverflowHidden ? 'overflow-hidden' : ''
          }`}
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div
            className={`${className} max-h-screen ${
              isOverflowAuto && 'overflow-auto'
            } px-4 pt-5 pb-4 sm:p-10 rounded-lg`}
          >
            {isCrossIconShown && (
              <button
                onClick={handleCrossIconClick}
                className="absolute top-0 right-0 w-5 mt-2 mr-3 cursor-pointer md:mt-4 md:mr-5 text-grey-800 focus:outline-none hover:text-orange"
              >
                <X />
              </button>
            )}
            {children}
          </div>
        </div>
      </>
    </ReactModal>
  )
}

export default Modal
