import { hasRoute } from '@common/utils'
import { useSessionContext } from '@contexts/session'
import minimatch from 'minimatch'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

const CHATWOOT_WEBSITE_TOKEN = {
  dev: 'wBrMPNEPdwGHHj7h6EGo6SHA',
  teacher: 'kncLwsRVKK1zy18KcHCwpQv2',
  paidUser: 'cL7vMFHGgwvYKTm6qevjZS8D',
  other: 'of4aGq2QGRR5UKnwi12tFZuT',
}

const CHATWOOT_EXCLUDE_GLOBS_OBJ = {
  allUsers: [
    '/book-trial-class*',
    // '/competitions/**/problems/**',
    '/feedback/**',
    '/quizzes/**/arena**',
    '/lp/**',
    '/benefits-of-coding*',
    '/student/dashboard**',
    '/competitions/**/register/complete/**',
    '/student/dashboard/**',
    '/trial-class/persona*',
    '/trial-class/booking**',
    '/trial-class/spoken-languages**',
    '/payment-partners*',
    '/curriculum*',
    '/payment-status*',
    '/payment/**',
  ],
  students: ['/batches/**'],
  teachers: [],
}

declare global {
  interface Window {
    $chatwoot: any
    chatwootSDK: any
    chatwootSettings: any
  }
}

const ChatwootWidget = () => {
  const {
    teacher: isTeacher,
    isPaidUser,
    loggedIn,
    id,
    email,
    name,
    avatar,
    phone,
  } = useSessionContext()

  const isProd = process.env.NODE_ENV === 'production'

  const router = useRouter()
  const { pathname } = useRouter()

  const checkShouldShowWidget = useCallback(() => {
    // remove for paid student
    if (
      !isTeacher &&
      isPaidUser &&
      !hasRoute(pathname, ['/competitions/**/problems/**'])
    )
      return false

    if (
      isTeacher &&
      CHATWOOT_EXCLUDE_GLOBS_OBJ.teachers.some((glob) =>
        minimatch(router.pathname, glob)
      )
    )
      return false

    if (
      loggedIn &&
      !isTeacher &&
      CHATWOOT_EXCLUDE_GLOBS_OBJ.students.some((glob) =>
        minimatch(router.pathname, glob)
      )
    )
      return false

    if (
      CHATWOOT_EXCLUDE_GLOBS_OBJ.allUsers.some((glob) =>
        minimatch(router.pathname, glob)
      )
    )
      return false
    return true
  }, [router.pathname, loggedIn, isTeacher])

  const websiteToken = !isProd
    ? CHATWOOT_WEBSITE_TOKEN.dev
    : isTeacher
    ? CHATWOOT_WEBSITE_TOKEN.teacher
    : isPaidUser
    ? CHATWOOT_WEBSITE_TOKEN.paidUser
    : CHATWOOT_WEBSITE_TOKEN.other

  // Checks whether to hide the widget or not.
  // Also, checks whether to show already hidden widget
  useEffect(() => {
    try {
      if (!window.$chatwoot) return
      const shouldShowWidget = checkShouldShowWidget()
      window.$chatwoot?.toggleBubbleVisibility(
        shouldShowWidget ? 'show' : 'hide'
      )
    } catch (error) {
      console.error(error)
    }
  }, [router.pathname])

  // Sends user's info to chatwoot when the widget is loaded
  useEffect(() => {
    const handleSetUserInfo = () => {
      try {
        if (!loggedIn) return
        window.$chatwoot?.setUser(id, {
          email,
          name,
          ['avatar_url']: avatar,
          ['phone_number']: phone,
        })
      } catch (error) {
        console.error(error)
      }
    }
    window.addEventListener('chatwoot:ready', handleSetUserInfo)
    return () => window.removeEventListener('chatwoot:ready', handleSetUserInfo)
  }, [loggedIn])

  // Loads the widget if it is supposed to be shown on a particular path
  useEffect(() => {
    const shouldShowWidget = checkShouldShowWidget()
    if (!shouldShowWidget) return

    // If already mounted
    if (window.$chatwoot) return

    // Initializing chat widget
    window.chatwootSettings = {
      hideMessageBubble: false,
      position:
        isTeacher || hasRoute(pathname, ['/competitions/**/problems/**'])
          ? 'left'
          : 'right',
      locale: 'en',
      type: 'standard',
    }
    ;(function (d, t) {
      const BASE_URL = 'https://support.codingal.com'
      const g: any = d.createElement(t),
        s = d.getElementsByTagName(t)[0]
      g.src = BASE_URL + '/packs/js/sdk.js'
      g.defer = true
      g.id = 'chatwoot-script-tag'
      g.async = true
      s.parentNode.insertBefore(g, s)
      g.onload = function () {
        window.chatwootSDK.run({
          websiteToken: websiteToken,
          baseUrl: BASE_URL,
        })
      }
    })(document, 'script')
  }, [router.pathname])

  // Reloading to re-initialize widget. Chatwoot currently does not provide options
  // to change inboxes for the already loaded widgets nor does it have a method to destroy the widget.
  useEffect(() => {
    return () => {
      if (!loggedIn) router.reload()
    }
  }, [loggedIn])

  return null
}

export default ChatwootWidget
